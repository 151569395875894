<template>
  <div class="container px-0 h-100">
    <div class="dashboard-wrapper">
      <div class="box1">
        <div class="card card-transparent">
          <div class="card-header">
            <div class="card-title">
              <h3 class="text-secondary">Przygotuj kalkulację</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="card-boxes">
              <router-link :to="{ name: 'calculator-auto' }" class="card-box">
                <div class="icon">
                  <img src="/media/pages/dashboard/dashboard-car-new.svg" alt="" />
                </div>
                <div class="title">AUTO</div>
              </router-link>
              <router-link :to="{ name: 'calculator-property' }" class="card-box">
                <div class="icon">
                  <img
                    src="/media/pages/dashboard/dashboard-property-new.svg"
                    alt=""
                  />
                </div>
                <div class="title">DOM</div>
              </router-link>
              <router-link :to="{ name: 'calculator-travel' }" class="card-box">
                <div class="icon">
                  <img
                    src="/media/pages/dashboard/dashboard-travel-new.svg"
                    alt=""
                  />
                </div>
                <div class="title">PODRÓŻ</div>
              </router-link>
              <router-link :to="{ name: 'calculator-life' }" class="card-box">
                <div class="icon">
                  <img src="/media/pages/dashboard/dashboard-life-new.svg" alt="" />
                </div>
                <div class="title">ŻYCIE</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="wide-box0">
        <div class="card card-transparent">
          <div class="card-header d-flex align-items-end">
            <div class="card-title flex-grow-1">
              <h3 class="text-secondary">Skróty</h3>
            </div>
            <div class="card-header-tools">
              <img
                src="/media/pages/dashboard/dashboard-edit.svg"
                class="cursor-pointer"
                alt=""
              />
            </div>
          </div>
          <div class="card-body">
            <div class="card-boxes4 ">
              <a href="#" class="card-box">
                <div class="title">Wyszukiwarka</div>
              </a>
              <router-link :to="{ name: 'register-policy' }" class="card-box">
                <div class="title">Rejestruj polisę</div>
              </router-link>
              <a href="#" class="card-box">
                <div class="title">Utwórz sprawę</div>
              </a>
              <a href="#" class="card-box">
                <div class="title">Kontakty</div>
              </a>
              <a href="#" class="card-box">
                <div class="title">Mail IDD + APK</div>
              </a>
              <a href="#" class="card-box">
                <div class="title">Moja Agencja</div>
              </a>
              <a href="#" class="card-box">
                <div class="title">Informator</div>
              </a>
              <a href="#" class="card-box">
                <div class="title">Kalendarz</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="box4">
        <div class="card border-radius-1">
          <div class="card-header d-flex align-items-end">
            <div class="card-title flex-grow-1">
              <h3 class="text-secondary">Ważne wiadomości</h3>
            </div>
            <div class="card-header-tools">
              <img
                src="/media/pages/dashboard/dashboard-external.svg"
                class="cursor-pointer"
                alt=""
              />
            </div>
          </div>
          <div class="card-body">
            <div class="notifications-list">
              <div class="notification">
                <div class="text text-danger">
                  PZU Auto AC – odstępuje od obowiązku wykonywania oględzin
                  pojazdów i zdjęć
                </div>
                <div class="icon">
                  <img
                    src="/media/pages/dashboard/dashboard-arrow-right.svg"
                    class="cursor-pointer"
                    alt=""
                  />
                </div>
              </div>
              <div class="notification">
                <div class="text text-danger">
                  PZU Auto AC – odstępuje od obowiązku wykonywania oględzin
                  pojazdów i zdjęć
                </div>
                <div class="icon">
                  <img
                    src="/media/pages/dashboard/dashboard-arrow-right.svg"
                    class="cursor-pointer"
                    alt=""
                  />
                </div>
              </div>
              <div class="notification">
                <div class="text text-danger">
                  PZU Auto AC – odstępuje od obowiązku wykonywania oględzin
                  pojazdów i zdjęć
                </div>
                <div class="icon">
                  <img
                    src="/media/pages/dashboard/dashboard-arrow-right.svg"
                    class="cursor-pointer"
                    alt=""
                  />
                </div>
              </div>
              <div class="notification">
                <div class="text">
                  Przerwa w dostępie do usługi Hestia WS w dniach 20-22
                  listopada 2020
                </div>
                <div class="icon">
                  <img
                    src="/media/pages/dashboard/dashboard-arrow-right.svg"
                    class="cursor-pointer"
                    alt=""
                  />
                </div>
              </div>
              <div class="notification">
                <div class="text text-danger">
                  Awaria w Allianz - wystawianie polis z cofniętym okresem
                  ochrony
                </div>
                <div class="icon">
                  <img
                    src="/media/pages/dashboard/dashboard-arrow-right.svg"
                    class="cursor-pointer"
                    alt=""
                  />
                </div>
              </div>
              <div class="notification">
                <div class="text">
                  Rozliczenie polis za lipiec 2021 - gotowe
                </div>
                <div class="icon">
                  <img
                    src="/media/pages/dashboard/dashboard-arrow-right.svg"
                    class="cursor-pointer"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wide-box">
        <div class="card border-radius-1">
          <div class="card-header d-flex align-items-end">
            <div class="card-title flex-grow-1">
              <h3 class="text-secondary">Wznowienia</h3>
            </div>
            <div class="card-header-tools">
              <img
                src="/media/pages/dashboard/dashboard-external.svg"
                class="cursor-pointer"
                alt=""
              />
            </div>
          </div>
          <div class="card-body">
            <table class="table table-custom-1">
              <thead>
                <tr>
                  <th style="width: 200px">Klient</th>
                  <th style="width: 20px">Dni</th>
                  <th style="width: 130px; padding-left: 45px">Data końca</th>
                  <th style="width: 150px; padding-left: 45px">Polisa</th>
                  <th style="width: 120px">TU</th>
                  <th style="width: 70px"></th>
                  <th class="text-end">Składka</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(renewal, index) in renewals" :key="index">
                  <td style="width: 200px">
                    <router-link
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      to="/"
                    >
                      <a href="#" style="color: rgba(5, 77, 172, 0.6)">
                        {{ renewal.client }}
                      </a>
                    </router-link>
                  </td>
                  <td class="text-end" style="width: 20px">
                    <div :style="'color: ' + getRenewalDaysColor(renewal.days)">
                      {{ renewal.days }}
                    </div>
                  </td>
                  <td style="width: 130px; padding-left: 45px">
                    {{ renewal.endDate }}
                  </td>
                  <td style="width: 150px; padding-left: 45px">
                    <router-link
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      to="/"
                    >
                      <a href="#" style="color: rgba(5, 77, 172, 0.6)">
                        {{ renewal.policyId }}
                      </a>
                    </router-link>
                  </td>
                  <td style="width: 120px">
                    {{ renewal.insurer }}
                  </td>
                  <td style="width: 70px" class="text-center">
                    <img
                      v-if="renewal.type === 'auto'"
                      src="/media/pages/dashboard/dashboard-table-car.svg"
                      alt=""
                    />
                    <img
                      v-if="renewal.type === 'zycie'"
                      src="/media/pages/dashboard/dashboard-table-house.svg"
                      alt=""
                    />
                    <img
                      v-if="renewal.type === 'majatek'"
                      src="/media/pages/dashboard/dashboard-table-travel.svg"
                      alt=""
                    />
                  </td>
                  <td class="text-end">{{ renewal.price }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="wide-box-2">
        <div class="card border-radius-1">
          <div class="card-header d-flex align-items-end">
            <div class="card-title flex-grow-1">
              <h3 class="text-secondary">Sprawy</h3>
            </div>
            <div class="card-header-tools">
              <img
                src="/media/pages/dashboard/dashboard-external.svg"
                class="cursor-pointer"
                alt=""
              />
            </div>
          </div>
          <div class="card-body">
            <table class="table table-custom-1">
              <thead>
                <tr>
                  <th style="width: 190px">Klient</th>
                  <th style="width: 95px" class="text-end">Data kontaktu</th>
                  <th
                    style="width: 40px; padding-left: 65px"
                    class="text-center"
                  >
                    Polisa
                  </th>
                  <th style="padding-left: 70px">Notatka</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(issue, index) in issues" :key="index">
                  <td style="width: 190px">
                    <router-link
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      to="/"
                    >
                      <a href="#" style="color: rgba(5, 77, 172, 0.6)">
                        {{ issue.client }}
                      </a>
                    </router-link>
                  </td>
                  <td style="width: 95px" class="text-end">
                    {{ issue.contactDate }}
                  </td>
                  <td
                    style="width: 100px; padding-left: 60px"
                    class="text-center"
                  >
                    <div>
                      <img
                        v-if="issue.policy === 'AUTO'"
                        src="/media/pages/dashboard/dashboard-table-car.svg"
                        alt=""
                      />
                      <img
                        v-if="issue.policy === 'DOM'"
                        src="/media/pages/dashboard/dashboard-table-house.svg"
                        alt=""
                      />
                      <img
                        v-if="issue.policy === 'ZYCIE'"
                        src="/media/pages/dashboard/dashboard-table-travel.svg"
                        alt=""
                      />
                    </div>
                  </td>
                  <td style="padding-left: 70px">{{ issue.notice }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RENEWAL_LIST } from "@/core/services/store/renewal.module";
import { mapGetters } from "vuex";
import { renewals } from "@/core/mock/mock.service.js";

export default {
  name: "Dashboard",
  data() {
    return {
      isEditingShortcuts: false,
      shortcuts: [],
      shortcutsAvailable: [],
      issues: [
        {
          id: 0,
          client: "Janusz Modzelewski",
          contactDate: "04-08-2021",
          policy: "AUTO",
          notice: "Klient chce ubezpieczyć auto w leasingu..."
        },
        {
          id: 1,
          client: "Ewa Nowakowska",
          contactDate: "11-08-2021",
          policy: "DOM",
          notice: "DOM w budowie pod lasem z dużym basenem..."
        },
        {
          id: 2,
          client: "EXIM sp. z o.o.",
          contactDate: "20-08-2021",
          policy: "ZYCIE",
          notice: "Firma z ubezpieczeniem floty 20 aut + OC..."
        }
      ],
      initialShortcuts: [
        {
          id: 0,
          name: "Rejestruj polisę",
          order: 1,
          url: "register-policy"
        },
        {
          id: 1,
          name: "Utwórz sprawę",
          order: 2,
          url: ""
        },
        {
          id: 2,
          name: "Moja agencja",
          order: 3,
          url: ""
        },
        {
          id: 3,
          name: "Kontakty",
          order: 4,
          url: ""
        }
      ],
      initialShortcutsAvailable: [
        {
          id: 0,
          name: "Moje polisy",
          order: 1,
          url: ""
        },
        {
          id: 1,
          name: "Moje sprawy",
          order: 2,
          url: ""
        },
        {
          id: 2,
          name: "Wznowienia",
          order: 3,
          url: ""
        },
        {
          id: 3,
          name: "Upoważnienia",
          order: 4,
          url: ""
        },
        {
          id: 4,
          name: "Szkolenia",
          order: 5,
          url: ""
        },
        {
          id: 5,
          name: "Rozliczenia",
          order: 6,
          url: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["renewals"])
  },
  mounted() {
    this.setupShortcuts();
    setTimeout(() => {
      this.$store.dispatch(RENEWAL_LIST, renewals);
    }, 2000);
  },
  methods: {
    handleAddShortcut(id) {
      const find = this.shortcutsAvailable.find(o => o.id === id);
      this.shortcuts.push(find);
      localStorage.setItem("shortcuts", JSON.stringify(this.shortcuts));

      const index = this.shortcutsAvailable.findIndex(o => o.id === id);
      if (index !== -1) {
        this.shortcutsAvailable.splice(index, 1);
      }
      localStorage.setItem(
        "shortcutsAvailable",
        JSON.stringify(this.shortcutsAvailable)
      );
    },
    handleRemoveShortcut(id) {
      const find = this.shortcuts.find(o => o.id === id);
      this.shortcutsAvailable.push(find);
      localStorage.setItem(
        "shortcutsAvailable",
        JSON.stringify(this.shortcutsAvailable)
      );

      const index = this.shortcuts.findIndex(o => o.id === id);
      if (index !== -1) {
        this.shortcuts.splice(index, 1);
      }
      localStorage.setItem("shortcuts", JSON.stringify(this.shortcuts));
    },
    getRenewalDaysColor(days) {
      if (days > 14) {
        return "#0A920A";
      } else if (days <= 14 && days >= 2) {
        return "#E12424";
      } else if (days <= 2) {
        return "#920A0A";
      }
    },
    setupShortcuts() {
      const shortcuts = localStorage.getItem("shortcuts")
        ? JSON.parse(localStorage.getItem("shortcuts"))
        : null;
      if (shortcuts === null) {
        localStorage.setItem(
          "shortcuts",
          JSON.stringify(this.initialShortcuts)
        );
        this.shortcuts = this.initialShortcuts;
      } else {
        this.shortcuts = shortcuts;
      }

      const shortcutsAvailable = localStorage.getItem("shortcutsAvailable")
        ? JSON.parse(localStorage.getItem("shortcutsAvailable"))
        : null;

      if (shortcutsAvailable === null) {
        localStorage.setItem(
          "shortcutsAvailable",
          JSON.stringify(this.initialShortcutsAvailable)
        );
        this.shortcutsAvailable = this.initialShortcutsAvailable;
      } else {
        this.shortcutsAvailable = shortcutsAvailable;
      }
    }
  }
};
</script>
